<template>
  <div class="a-detail">
    <div class="top-c">
      <div><span class="title">{{appraisalData ? appraisalData.title : ''}}</span><span class="note">{{appraisalData ? appraisalData.statusText : ''}}</span></div>
      <div class="remark">{{appraisalData ? appraisalData.remark : ''}}</div>
      <div class="remark" v-if="chooseDataDetail">{{chooseDataDetail.userName}}</div>
    </div>
    <div class="bottom-c-user" v-if="isCheck && !questioning">
      <div class="user-item" :class="{'user-item-wap': clientType === 'wap'}" v-for="(item, index) in bkhList" :key="item.id" @click="next(index)">
        <div class="title">
          <div>{{item.userName}}</div>
        </div>
        <div class="status">
          <div>{{resultMap[item.id].isOver ? '已完成' : '未完成'}}</div>
        </div>
        <div class="by"></div> 
        <div class="by-x"></div> 
      </div>
    </div>
    <div class="bottom-c" v-if="isCheck && questioning && chooseDataDetail">
      <div class="q-item" v-for="appraisalItem in appraisalItems" :key="chooseIndex + '_' + appraisalItem.id">
        <ItemForm @valueChange="saveChange(appraisalItem.id)" :his-value="resultMap[chooseDataDetail.id][appraisalItem.id]" :ref="'appraisalItem_' + appraisalItem.id" :appraisal-item="appraisalItem" ></ItemForm>
      </div>
      <div class="btn-s"><fm-btn @click="next(chooseIndex - 1)">上一位</fm-btn><fm-btn @click="next(chooseIndex + 1)">下一位</fm-btn><fm-btn @click="goBack">关闭</fm-btn></div>
    </div>
  </div>
</template>

<script>
import ItemForm from './cmp/ItemForm'

import {
  appraisalRequest as request,
  appraisalGroupDetailRequest,
  appraisalItemRequest,
  appraisalResultRequest
} from '../../api'

export default {
  components: {
    ItemForm
  },
  data () {
    return {
      chooseIndex: 0,
      questioning: false,
      isCheck: false,
      appraisalItems: [],
      appraisalId: null,
      mxList: [],
      bkhList: [],
      appraisalGroupDetailId: null,
      appraisalData: null,
      chooseDataDetail: null
    }
  },
  computed: {
    resultMap () {
      let data = {}
      this.bkhList.forEach(v => {
        data[v.id] = {
          isOver: true
        }
        this.appraisalItems.forEach(item => {
          let mx = this.mxList.find(v1 => v1.beAppraisalGroupDetailId === v.id && v1.appraisalItemId === item.id)
          data[v.id].isOver = data[v.id].isOver && mx && true
          data[v.id][item.id] = mx ? mx.value : null
          data[v.id]['r_' + item.id] = mx ? mx.remark : null
        })
      })
      return data
    },
    clientType () {
      return this.$store.getters.clientType
    },
    statusList () {
      return this.$store.getters.appraisalStatusList
    }
  },
  beforeRouteEnter (to, from, next) {
    if (to.query && to.query.id) {
      next(vm => vm.$nextTick(vm.init))
    } else {
      next(from)
    }
  },
  methods: {
    async init () {
      // 路由携带过来的考核id
      this.appraisalId = this.$router.history.current.query.id
      this.loadData()
      await this.loadBaseData()
      if (this.isCheck && this.appraisalItems.length > 0) {
        this.loadDetailList()
        this.loadMxList()
      } else if (!this.isCheck) {
        this.$notice.info({
          title: '系统提示',
          desc: '无考核权限'
        })
      } else {
        this.$notice.info({
          title: '系统提示',
          desc: '无考核项目'
        })
      }
    },
    async saveChange (appraisalItemId) {
      let p = this.$refs['appraisalItem_' + appraisalItemId][0].getValue()
      Object.assign(p, {
        appraisalId: this.appraisalId,
        appraisalItemId: appraisalItemId,
        beAppraisalGroupDetailId: this.chooseDataDetail.id,
        appraisalGroupDetailId: this.appraisalGroupDetailId
      })
      console.log(p)
      if (p.value !== this.resultMap[this.chooseDataDetail.id][appraisalItemId] || p.remark !== this.resultMap[this.chooseDataDetail.id]['r_' + appraisalItemId]) {
        await appraisalResultRequest.add(p)
        this.$notice.success({
          title: '系统提示',
          desc: '打分完成'
        })
        await this.loadMxList()
      }
    },
    async goBack () {
      this.chooseIndex = 0
      this.chooseDataDetail = null
      this.questioning = false
    },
    async next (index) {
      if (index < 0) {
        this.$notice.success({
          title: '系统提示',
          desc: '没有上一个了'
        })
        return
      }
      if (index > this.bkhList.length - 1) {
        this.$notice.success({
          title: '系统提示',
          desc: '没有下一个了'
        })
        return
      }
      this.$el.scrollTop = 0
      this.chooseIndex = index
      this.chooseDataDetail = this.bkhList[this.chooseIndex]
      this.questioning = true
      this.$nextTick(() => {
        let i = 0
        while (i < this.appraisalItems.length) {
          let item = this.appraisalItems[i]
          this.$refs['appraisalItem_' + item.id][0].setValue({
            value: this.resultMap[this.chooseDataDetail.id][item.id],
            remark: this.resultMap[this.chooseDataDetail.id]['r_' + item.id]
          })
          i += 1
        }
      })
    },
    async loadMxList () {
      let parm = {
        appraisalGroupDetailId: this.appraisalGroupDetailId
      }
      this.mxList = await appraisalResultRequest.getDetail(parm)
    },
    async loadBaseData () {
      let parm = {
        appraisalId: this.appraisalId
      }
      let data = await appraisalGroupDetailRequest.getMy(parm)
      data.forEach((v) => {
        if (v.appraisalGroupType === 'check') {
          this.appraisalGroupDetailId = v.id
          this.isCheck = true
        }
      })
      if (this.isCheck) {
        let appraisalItems = await appraisalItemRequest.get(parm)
        appraisalItems.forEach(v => v.ruleMap = v.rule ? JSON.parse(v.rule) : {
          type: 'input'
        })
        this.appraisalItems = appraisalItems
      }
    },
    async loadData () {
      this.loading = true
      let parm = {id: this.appraisalId}
      request.get(parm).then((data) => {
        data.forEach(v => v.statusText = (this.statusList.find(v1 => v1.key === v.status) || {label: ''}).label)
        if (data.length == 0) {
          this.$notice.info({
            title: '系统提示',
            desc: '考核不存在'
          })
        } else {
          this.appraisalData = data[0]
        }
        this.loading = false
      }).catch(() => {
        this.loading = false
      })
    },
    async loadDetailList () {
      let parm = {
        appraisalId: this.appraisalId,
        appraisalGroupType: 'becheck'
      }
      let detailList = await appraisalGroupDetailRequest.get(parm)
      detailList.sort((a, b) => (a.orderNum || 1000) - (b.orderNum || 1000))
      this.bkhList = detailList
    }
  }
}
</script>

<style lang="less" scoped>
.btn-s {
  display: flex;
  justify-content: space-around;
  margin: 1rem 0;
}
.a-detail {
  overflow: auto;
  .fm-input-new {
    width: 142px !important;
    height: 32px;
  }
  .bkhr-btns {
    .fm-btn-norm {
      height: 32px !important;
      width: 96px !important;
      border-radius: 16px !important;
    }
  }
}
</style>


<style lang="less" scoped>
.user-item {
  background: #fff;
  position: relative;
  width: 280px;
  height: 120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 16px 0 0 16px;
  .by {
    position: absolute;
    left: -3px;
    top: -16px;
    width: 100px;
    height: 50px;
    border-bottom-right-radius: 100px;
    border-bottom-left-radius: 100px;
    background: #fff7f9;
  }
  .by-x {
    position: absolute;
    right: -16px;
    bottom: -16px;
    width: 100px;
    height: 50px;
    border-top-right-radius: 100px;
    border-top-left-radius: 100px;
    background: #fff7f9;
  }
  .title {
    font-size: 20px;
    color: #707070;
  }
  .status {
    color: #F4628F;
    width: 96px;
    height: 32px;;
    display: flex;
    justify-content: center;
    border: 1px solid #FB6D8F;
    border-radius: 16px;
    align-items: center;
    margin-top: 20px;
  }
}
.user-item-wap {
  width: calc(100% - 32px);
}
.q-item {
  background: #fff;
  margin: 14px 14px 0;
  display: flex;
  justify-content: space-between;
  padding: 28px;
}
.br-t {
  font-size: 14px;
  color: rgba(0,0,0,0.65);
  line-height: 36px;
  display: flex;
  div {
    margin: 9px 9px 0 2px;
    transform: rotate(180deg);
    width: 18px;
    height: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    i {
      color: rgba(0,0,0,0.65) !important;
    }
  }
}
.show-all-btn {
  display: flex;
  justify-content: center;
  margin-top: 16px;
  div {
    line-height: 22px;
    color: #00C8C4;
    font-size: 14px;
  }
}
.top-c {
  background: #fff;
  padding: 14px 28px;
  .title {
    line-height: 22px;
    font-size: 16px;
    color: #333145;
  }
  .note {
    color: #00C8C4;
  }
  .remark {
    color: rgba(0,0,0,0.45);
    font-size: 14px;
    line-height: 23px;
    padding-top: 14px;
  }
}
.not-text {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  color: #999;
  font-size: 2rem;
  padding: 0 2rem 4rem;
}
.bkhr-list-none {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #999;
  font-size: 2rem;
}
.bkhr-list-none {
  flex: 1;
}
.bkhr-item {
  position: relative;
  padding: 16px 20px;
  margin: 8px;
  line-height: 2rem;
  background: #FDFDFD;
  color: rgba(0,0,0,0.65);
  line-height: 36px;
  font-size: 14px;
  .yy-fz {
    font-size: 32px;
    line-height: 45px;
    position: absolute;
    top: 0;
    right: 22px;
  }
  .bkhr-btns {
    width: 100%;
    margin-top: 20px;
    display: flex;
    justify-content: center;
  }
}
.b-c-t {
  display: flex;
  flex: 1;
}
.b-r {
  flex: 1;
  margin: 8px;
  background: #fff;
}
.b-l {
  flex: 1;
}
.a-detail {
  display: flex;
  flex-direction: column;
  background: #fff7f9;
}
.content {
  flex: 1;
  display: flex;
  overflow-x: auto;
}
.group-item {
  min-width: 30%;
  max-width: 50%;
  flex: 1;
  margin: 1rem;
  border: 1px solid #eee;
}
.bottom-c-user {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
}
.bottom-c {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.n-g {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  color: #999;
}
.a-remark {
  color: #777;
  margin: 1rem 2rem;
  line-height: 2;
}
</style>